p {
    font-family: sans-serif;
    color: #FFF;
}

li p {
    text-align: left;
}

li {
    color: white;
}

.text-section {
    text-align: justify;
    text-justify: inter-word;
    height: 100%;
    padding: 15px;
}


h1 {
    font-family: sans-serif;
    font-size: 32px;
    color: #FFF;
}

.small-text {
    font-size: 16px;
    padding: 15px;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.circular-image-wrap {
    width: 100%;
    height: 250px;
}

.circular-image {
    width: 100%;
    height: 100%;
}

.circular-image-img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
}

.rect-image {
    width: 100%;
}

.rect-img {
    object-fit: scale-down;
    width: 300px;
}

.link-image-wrap {
    float: left;
    height: 100%;
    margin: 5px;
    padding: 10px;
}

.link-image {
    height: 30px;
    width: 40px;
    float: left;
    vertical-align: middle;
    object-fit: scale-down;
}

.link-text {
    font-size: 20px;
    float: left;
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;
    width: 100%;
}

.center {
    text-align: center;
    width: 100%;
}

button {
    border-radius: 5px;
}

html, body {
    max-width: 100%;
    max-height: 100%;
    overflow-x: hidden;

    height: 100%;
    width: 100%;
}

html {
    background-color: #00464F;
}

body {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

.link-wrap {
    width: 100%;
    max-width: 1000px;
}

.links-view {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

div {
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

.main-page-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #00464F;
    padding-top: 25px;
    padding-bottom: 25px;
}
.main-page {
    width: 65%;
    max-width: 1000px;
}

.info-view-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.name-text {
    margin-top: 15px;
}

.image-section {
    padding: 10px;
}

@media (max-width: 1000px) {
    .info-view-container {
        flex-direction: column;
    }

    .image-section {
        width: 100%;
        text-align: center;
    }

    .text-section {
        width: 100%;
        text-align: center;
    }

    .main-page {
        width: 80%;
    }

    h1 {
        display: none;
    }

    .text-section {
        text-align: justify;
        text-justify: inter-word;
    }
}

.para {
    line-height: 24px;
}
